.light {
  --text-color: #{ $colorBlack };
  --body-background-color: #{ $colorLightGray };

  --border-color: #{ $colorWhite };
  --input-color: #{ $colorLightGray };
}

.dark {
  --text-color: #{ $colorLightGray };
  --body-background-color: #{ $colorBlack };

  --border-color: #{ $colorBlack };
  --input-color: #{ $colorLightGray };
}