$fontAwesomeName: "Font Awesome 5 Free";

@font-face {
  font-family: Infernalda;
  src: local("Infernalda"), url("../resources/fonts/Infernalda.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Bubos;
  src: local("Bubos"), url("../resources/fonts/AnotherDanger.ttf");
  font-weight: normal;
  font-style: normal;
}
