// Color Variables

// Base
$colorBlack: #000000;
$colorGray: #737373;
$colorLightGray: #DDDDDD;
$colorGreen: #165B33; // Green
$colorRed: #BB2528; // Red
$colorWhite: #FFFFFF; // White
$colorPurple: #800080;
$colorTwitterBlue: #08A0E9;

// Halloween
$colorPumpkinOrange: #DF5823;

// Xmas
$colorGrinch: #85B00B;  // Grinch Green
$colorGold: #C1A520;  // Christmas Gold
