.splash-page {
  // padding-top: 1.5rem;
  height: 100vh;
  width: 100vw;
  display: grid;
  justify-content: center;
  place-items: center;
  grid-template-columns: [c1] 1fr [c2] 2fr [c3] 2fr [c4] 2fr [c5] 1fr;
  grid-template-rows: [r1] 1fr [r2] 2fr [r3] 1fr [r4] 2fr [r5] 1fr;
  grid-template-areas:
    "header header header header header"
    " . . . . ."
    "leftsidebar . main . rightsidebar"
    " . . . . ."
    "footer footer footer footer footer";

  .main-projects {
    grid-row: 2;
    grid-column: 3;
  }

  .launchpad-projects {
    grid-row: 4;
    grid-column: 3;
  }

  .project-image {
    height: 5vh;
  }

  .ghost-kid {
      grid-row: 2;
      grid-column: 5;
  }

  .header-link-button.bubos-link {
    font-family: bubos, sans-serif;
    grid-area: main;
  }

  .header-link-button.nofs-link {
    font-family: infernalda, sans-serif;
    grid-area: main;
  }

  .header-link-button.tune-link {
    font-family: arial, sans-serif;
    grid-area: main;
  }

  .nav-div {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .header {
    grid-area: header;
    place-content: center;
    text-align: center;
    font-size: 4rem;
    padding: 0.125rem;
    font-family: bubos, sans-serif;
    color: $colorGrinch;
    text-shadow:
      -1px -1px 3px var(--text-color),
      1px -1px 3px var(--text-color),
      -1px 1px 3px var(--text-color),
      1px 1px 3px var(--text-color);
  }

  .sub-title {
    padding-top: 5rem;
    font-size: 3rem;
    font-family: infernalda, sans-serif;
    color: $colorPumpkinOrange;
    grid-area: main;
    grid-row: 3;
    grid-column-start: 2;
    grid-column-end: 5;
    text-align: center;
  }

  .needle-gif,
  .potion-gif {
    height: 10vh;
    width: 10vh;
    justify-content: space-evenly;
    grid-area: leftsidebar;
  }

  .needle-gif {
    grid-row: 2;
  }

  .potion-gif {
    grid-row: 4;
  }

  .footer {
    grid-area: footer;
    width: 100%;
    display: flex;
    // position: absolute;
    // bottom: 1rem;
    justify-content: space-between;
  }
}
