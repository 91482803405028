button {
  font-size: 3vh;
  border-radius: 0.5rem;
  border-width: 0.25rem;
}

a {
  font-size: 3.5vh;
  color: inherit;
  text-decoration: none;
  padding: 0.5rem;

  &.active {
    background: var(--gold-gradient);
    background-size: 300% 300%;
    animation: gradient-animation  5s infinite;
  }
}

input {
  font-size: 100%;
  width: 10rem;
  background-color: var(--input-color);
}

input[type="number"]::-webkit-inner-spin-button {
  opacity: 1;
}

iframe {
  height: 85vh;
  width: 65vw;
}
